@import 'shared';

.presign-confirmation {
  &__qr-code {
    margin-top: $ray-spacing-xl;
    margin-bottom: $ray-spacing-xl;

    @include ray-breakpoint(phone, tablet) {
      text-align: center;
    }

    svg {
      border: $ray-border-width solid $ray-color-gray-10;
      border-radius: $ray-border-radius;
      padding: $ray-spacing-xs;

      @include ray-breakpoint(phone, tablet) {
        width: 90%;
        height: 90%;
      }
    }
  }
}
