@import 'shared';

$mirror-webcam: true;

@keyframes flash {
  50% {
    filter: brightness(50%);
  }
}

.photo-capture {
  margin: $ray-spacing-2xl 0;

  @include ray-breakpoint(tablet) {
    width: 50%;
  }

  &__camera {
    // Uses the same aspect ratio as the js component
    @include maintain-ratio(3 4);
    position: relative;
    background: $ray-color-gray-10;
    border-radius: $ray-border-radius;
    overflow: hidden;
  }

  &__webcam {
    @include absolute-center($mirror-webcam);
    border-radius: $ray-border-radius;
  }

  &__screenshot {
    @include absolute-center(true);

    img {
      animation-name: flash;
      animation-duration: 0.15s;
    }
  }

  &__webcam-loader {
    @include absolute-center;
    height: 50px;
  }

  &__webcam-error {
    @include absolute-center;
    width: 247px;
    text-align: center;
  }

  &__webcam-button {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -$ray-spacing-md;
  }

  &__clear-screenshot {
    position: absolute;
    top: $ray-spacing-sm;
    left: $ray-spacing-sm;
    cursor: pointer;
    z-index: 1;
  }
}
