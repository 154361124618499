@import 'shared';

.check-in-confirmation {
  &__title {
    margin-bottom: 0;
  }

  &__text {
    white-space: pre-wrap;
    margin: 0;
  }

  &__separator {
    margin: $ray-spacing-xl 0;
    border-top: $ray-border-width solid $ray-color-gray-11;
  }
}

.confirmation-card {
  border: $ray-border-width solid $ray-color-black;
  border-radius: $ray-border-radius;
  padding: $ray-spacing-xl $ray-spacing-md;
  margin-top: $ray-spacing-xl;

  @include ray-breakpoint(tablet) {
    width: max-content;
  }

  &__logo {
    margin-bottom: $ray-spacing-xl;

    svg {
      width: 5.25rem;
    }
  }

  &__date {
    margin-bottom: $ray-spacing-xs;
  }

  &__mono, &__time {
    margin-bottom: 0;
  }
}
