@import 'shared';

.image-upload {
  margin: $ray-spacing-2xl 0;

  @include ray-breakpoint(tablet) {
    width: 50%;
  }

  &__box {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23808080FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: $ray-border-radius;
    width: 100%;
    height: 387px;
    overflow: hidden;
    position: relative;

    label {
      width: 100%;
      height: 100%;
    }
  }

  &__screenshot {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__placeholder {
    @include absolute-center();
    white-space: nowrap;
  }
}
