@import 'shared';

.visit-details {
  &__separator {
    border-top: $ray-border-width solid $ray-color-gray-11;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    margin-top: $ray-spacing-2xl;

    @include ray-breakpoint(phone, tablet) {
      flex-direction: column;
    }
  }

  &__info-item {
    display: flex;
    margin-bottom: $ray-spacing-xl;
    flex-direction: column;

    @include ray-breakpoint(tablet) {
      flex: 0 50%;
    }

    span {
      margin: 0;
    }
  }

  &__time {
    @include ray-breakpoint(tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  &__building-link {
    display: flex;
    flex-direction: column;
  }
}
