@import '@wework/ray-core/scss/global/variables';
@import '@wework/ray-core/scss/global/typography';
@import '@wework/ray-core/scss/global/spacing/spacing';
@import '@wework/ray-core/scss/global/mixins/breakpoints';

$ray-color-gray-11: rgb(230, 230, 230);

@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}

@mixin absolute-center($mirror: false) {
  position: absolute;
  top: 50%;
  left: 50%;
  @if $mirror {
    transform: translate(-50%, -50%) scaleX(-1);
  } @else {
    transform: translate(-50%, -50%);
  }
}

.hide-phone {
  @include ray-breakpoint(phone, tablet) {
    display: none !important;
  }
}

.hide-tablet {
  @include ray-breakpoint(tablet) {
    display: none;
  }
}

.main-section {
  padding-top: $ray-spacing-2xl;
  padding-bottom: $ray-spacing-2xl * 2;

  h1 {
    @include ray-breakpoint(tablet) {
      margin-bottom: $ray-spacing-xl;
    }

    span {
      @include ray-breakpoint(desktop) {
        @include ray-display-1;
      }
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.button-loader-container {
  position: relative;

  & > .button-loader {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}
