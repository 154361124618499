@import 'shared';

.agreement {
  &__body-text {
    margin-bottom: $ray-spacing-xl;

    @include ray-breakpoint(phone, tablet) {
      max-height: 390px;
      overflow: auto;
      padding: $ray-spacing-xs;
      border-radius: $ray-border-radius;
      border: $ray-border-width solid $ray-color-gray-60;
    }
  }
}
