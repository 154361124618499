@import 'shared';

.visitor-form {
  &__field {
    margin-bottom: $ray-spacing-xl;
  }

  .ray-text-field [disabled],
  .ray-text-field [disabled] + .ray-text-field__label {
    color: $ray-color-gray-60;
    -webkit-text-fill-color: $ray-color-gray-60; /* required on Safari */
    opacity: 1; /* required on IOS */
  }
}
