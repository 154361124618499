@import 'shared';

.progress-bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: $ray-spacing-xs;
    margin-bottom: $ray-spacing-xl;

    &__step {
        height: 6px;
        background-color: $ray-color-blue-50;
        
        &--disabled {
            background-color: $ray-color-gray-10;
        }
    }
}
