@import 'shared';

.host-lookup {
    .search-icon,
    .close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .search-icon {
        margin-left: $ray-spacing-md;
    }
    .close-icon {
        right: 0;
        cursor: pointer;
        margin-right: $ray-spacing-md;
    }

    &__input {
        padding: 0 $ray-spacing-baseline * 3;
    }

    &__no-result {
        display: block;
        color: $ray-color-blue-50;
        text-align: center;
        border: none;
        background: none;
        cursor: pointer;
        margin-top: $ray-spacing-md;
        margin-left: auto;
        margin-right: auto;
        
        &:hover {
            opacity: 0.5;
        }
    }

    &__list-item {
        background-color: transparent;
        border: none;
        margin-bottom: $ray-spacing-md;
    }

    &__company {
        color: $ray-color-gray-60;
    }

    &__bottom {
        height: 200px;
    }

    &__next-wrapper {
        margin-top: $ray-spacing-xl;
    }
}

.react-autosuggest {
    &__container {
        width: 100%;
    }
    &__suggestions-container {
        display: none;
        &--open {
            display: block;
            margin-top: $ray-spacing-md;
        }
        @include ray-breakpoint(phone) {
            max-height: 237px;
            overflow: auto;
        }
        @include ray-breakpoint(tablet) {
            max-height: 350px;
            overflow: auto;
        }
    }
    &__suggestion {
        cursor: pointer;
        &:hover, &:focus, &--highlighted {
            opacity: 0.5;
        }
    }
}

.search-loader-container {
    position: relative;
    margin-top: $ray-spacing-xl;
  
    & > .search-loader {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }
